import React from 'react'
import PropTypes from 'prop-types'
import HTMLContent from '../HTMLContent'

const FullWidthPanel = ({ content_MD, inlineImages }) =>
  !!content_MD ? (
    <HTMLContent
      content={content_MD}
      className="panel-text"
      inlineImages={inlineImages}
    />
  ) : null

FullWidthPanel.propTypes = {
  content_MD: PropTypes.string,
  inlineImages: PropTypes.array,
}

export default FullWidthPanel
